export default {
	items: [
		{
			name: 'Dashboard',
			url: '/dashboard',
			icon: 'icon-speedometer',
			badge: {
				variant: 'primary'
			}
		},
		{
			name: 'Account Setup',
			url: '/setup',
			icon: 'icon-settings',
			children: [
				{
					name: 'Company',
					url: '/company',
					icon: 'fa fa-building-o'
				},
				{
					name: 'Storage Location',
					url: '/location',
					icon: 'fa fa-map-marker',
				},
				{
					name: 'User',
					url: '/user',
					icon: 'fa fa-users',
				},
				{
					name: 'Connection',
					url: '/connection',
					icon: 'fa fa-link',
				},
				{
					name: 'Transportation',
					url: '/transportation',
					icon: 'fa fa-truck'
				},
				{
					name: 'Asset Type',
					url: '/asset-type',
					icon: 'fa fa-tags',
				},
			]
		},
		{
			name: 'Asset Management',
			url: '/asset',
			icon: 'fa fa-tasks',
			children: [
				{
					name: 'Asset',
					url: '/asset',
					icon: 'fa fa-star'
				},
				{
					name: 'Asset Sticker Generator',
					url: '/sticker-generator',
					icon: 'fa fa-print'
				},
				{
					name: 'Asset Issuance',
					url: '/asset-issuance',
					icon: 'fa fa-tag'
				},
				{
					name: 'Asset History Log',
					url: '/asset-history-log',
					icon: 'fa fa-history'
				}
			]
		},
		{
			name: 'Transactions',
			url: '/transaction',
			icon: 'icon-list',
			children: [
				{
					name: 'Inventory Session',
					url: '/inventory-session',
					icon: 'fa fa-cubes'
				},
				{
					name: 'Dispatch',
					url: '/dispatch',
					icon: 'fa fa-truck',
				},
				{
					name: 'Receipt',
					url: '/receipt',
					icon: 'fa fa-map-pin'
				}
			]
		},
		{
			name: 'Reports',
			url: '/reports',
			icon: 'fa fa-line-chart',
			children: [
				{
					name: 'Asset Accountability',
					url: '/asset-accountability',
					icon: 'fa fa-list-alt'
				},
				{
					name: 'Asset Pool Distribution',
					url: '/asset-pool-distribution',
					icon: 'fa fa-pie-chart'
				},
				{
					name: 'Assets With Truckers',
					url: '/assets-with-truckers',
					icon: 'fa fa-truck'
				},
				{
					name: 'Inventory Compliance',
					url: '/inventory-compliance',
					icon: 'fa fa-check-circle'
				},
				{
					name: 'Inventory Summary',
					url: '/inventory-summary',
					icon: 'fa fa-table',
				},
				{
					name: 'User Inventory Scans',
					url: '/user-inventory-scans',
					icon: 'fa fa-barcode'
				},
			]
		},
		{
			name: 'Support',
			url: '/support',
			icon: 'fa fa-handshake-o',
			children: [
				{
					name: 'Transfer Company',
					url: '/transfer-company-requests',
					icon: 'fa fa-exchange'
				},
				{
					name: 'User Session Logs',
					url: '/user-session-logs',
					icon: 'fa fa-key'
				}
			]
		}
	]
};
